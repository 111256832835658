<template>
  <head>
    <link href="/css/styles.css" type="text/css" />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
    />
  </head>
  <div class="pageLayout">
    <div class="sky">
      <div class="contact container">
        <h1>Contact</h1>
        <div class="container-fluid contact">
          <p>
            <em>
              We are here to serve you, please reach out with any questions...
            </em>
          </p>
          <div class="architect">
            <div class="row">
              <div class="col-md-3">Architect:</div>
              <div class="col-md-5">Angus Walker</div>
            </div>
            <div class="row">
              <div class="col-md-3">Phone:</div>
              <div class="col-md-5">(813) 895-4265 <em>(Call or Text)</em></div>
            </div>
          </div>
          <br />
          <div class="emailblock">
            <div class="row">
              <div class="col-md-3">Email:</div>
              <div class="col-md-5 email">
                angusw@leadingedgetechnologyconsulting.com
              </div>
            </div>
          </div>
          <br />
          <div class="address">
            <div class="row">
              <div class="col-md-3">Billing Address:</div>
              <div class="col-md-5">Leading Edge Technology Consulting</div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-5">301 W. Platt Street Suite 397</div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-5">Tampa, FL 33606</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color: antiquewhite;
  color: black;
  opacity: 0.8;
}

html,
body {
  height: 100%;
}

h1 {
  color: white;
}
.pageLayout {
  /* max-width: 750px; */
  min-height: 400px;
}
.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}

.email {
  color: blue;
}

.architect {
  background-color: aqua;
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.address {
  background-color:rgb(237, 154, 237);
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.emailblock {
  background-color: salmon;
  color: white;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}
</style>
