<template>
  <div class="pageLayout">
    <div class="sky">
      <div class="projects container">
        <h1>Leading Edge Technology Consulting</h1>
        <h3>A Full Service Development Consulting Firm</h3>
        <div class="container-fluid">
          <div class="primary">
            <h4><em>Primary Services Offered:</em></h4>
            <div>
              <ul>
                <li col-sm-8>
                  Provide custom development support on new and exising projects.
                </li>
                <li col-sm-8>
                  Develop secure APIs for backend legacy systems to
                  expose B2B operations with modern RESTful APIs to trusted partners and
                  clients.
                </li>
                <li col-sm-8>
                  Build custom client facing and internal web applications.
                </li>
                <li col-sm-8>
                  Automate business processes with IT auotmation and Artifical Intelligence
                  (AI) and custom code to provide positive ROI.
                </li>
                <li col-sm-8>
                  Architect and setup Containerized solutions using Docker.
                </li>
                <li col-sm-8>
                  Architect efficient deployment pipelines using CI/CD with
                  Azure Dev Ops.
                </li>
                <li col-sm-8>
                  Design Agile and Waterfall based SDLC models to fit specific project
                  needs.
                </li>
                <li col-sm-8>
                  Provide Project Management and planning / monitoring to ensure on time and on budget delivery.
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div class="locale">
            <h4><em>Work Locale:</em></h4>
            <ul>
              <li><b>On-Site:</b> LETC consultants often work onsite in close collaboration with existing client team; 
                this provides for knowledge transfer, cross-pollenization of ideas, and an experienced in-place 
                client team once the project is finished. </li>
              <li><b>Remote:</b> In recent years, many firms have become more comfortable with a remote or partially remote work force; 
                the advantages of being able to pull in experts from across a nation while minimizing the burden of travel costs as well 
                as the related environmental impact,  has transformed the way many projects are completed. 
                LETC fully supports both remote and on-site collaboration models.  
                We are flexible to meet our client's needs and cultures. 
                </li>
                <li>LETC leverages Microsoft Teams for collaboration meetings, and supports the use of cloud based 
                  infrastructure for development pipelines including Azure DevOps and Continuous Integration (CI) / Continuous Delivery (CD).
                  Azure DevOps cloud based pipelines lend themselves well to a distributed development team.</li>
            </ul>
          </div>
          <br/>
          <div class="platforms">
            <h4><em>Platforms/Languages Supported:</em></h4>
            <ul>
              <li col-sm-8>Windows, Linux, WSL, and Apple Operating Systems.</li>
              <li col-sm-8>Recommended Linux Distributions:</li>
              <ul>
                <li>Centos - Red Hat.</li>
                <li>Debian - Ubuntu.</li>
              </ul>
              <li col-sm-8>Microsoft .NET (Framework and Core).</li>
              <li col-sm-8>On-premises and Cloud (Azure / AWS / Linode).</li>
              <li col-sm-8>
                Physical Servers, Virtual Servers and Containerized (Docker)
                Solutions.
              </li>
              <li col-sm-8>
                Active Directory-based integrated security, including tokenized
                secure access, JWT and PKI Encryptions.
              </li>
              <li col-sm-8>
                C#, ASP.NET, MVC, WPF, WinForms, APIs documented with swagger
                and supporting asynchronous operations.
              </li>
              <li col-sm-8>
                COBOL, especially COBOL augmented with C custom data types (such
                as Guids).
              </li>
              <li col-sm-8>
                Web Technologies: Vue, Javascript, CSS, axios, cUrl.
              </li>
              <li col-sm-8>
                Databases: SQL Server - Enterprise Architure (AlwaysOn, HA, DR,
                Failover Clustering), MySql, Cassandra, Oracle.
              </li>
            </ul>
          </div>
          <p class="tagline"><em>Welcome to Leading Edge Technology Consulting!</em> </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
<style scoped>
.pageLayout {
  /* max-width: 750px; */
  min-height: 800px;
}

h1,
h2 {
  color: white;
}

.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}

ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}

button {
  background-color: blue;
  color: white;
  border-radius: 10px;
}
.ListBlock {
  background-color: grey;
  opacity: 0.5;
  color: black;
}

.home {
  margin-top: 10px;
  margin-left: 20px;
}
.tagline{
  margin: 10px;

}
ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}
h1 {
  color: white;
}
h3 {
  color: white;
}
.logo {
  width: 200px;
  height: 200px;
}
.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color: antiquewhite;
  color: black;
  opacity: 0.8;
}

.primary {
  background-color: aqua;
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.locale {
  background-color:rgb(237, 154, 237);
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.platforms {
  background-color: salmon;
  color: white;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

html,
body {
  height: 100%;
}
</style>
