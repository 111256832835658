<template>
  <div class="pageLayout">
    <div class="sky">
      <div class="languages, container">
        <h1>Our Coding Languages and Platforms:</h1>
        <div class="container-fluid">
          
            <p>
              <em>
                When it comes to coding, our consultants speak your language.  
                Our experienced team takes pride in their breadth and depth of knowledge of coding languages and platforms, 
                which allows them to efficiently formulate effective solutions to your business needs.
              </em>
            </p>
            <div class="languages">
            <ul>
              <div
                class="list"
                v-for="(item, index) in progammingLanguages"
                v-bind:key="index"
              >
                <li>{{ item.language }}</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
export default {
  name: "App",
  data() {
    return {
      homeTitle: "Industries Served",
      industries: [
        { industry: "Healthcare" },
        { industry: "Insurance" },
        { industry: "Banking" },
        { industry: "Financial Services" },
        { industry: "High Tech" },
        { industry: "Manufacturing" },
      ],
      projectTypes: [
        {
          project:
            "B2B Interface - Securely expose legacy systems to Clients via realtime API",
        },
        { project: "API's -> RESTful: xml and json w/ Swagger" },
        { project: "Web Applications: Three tier .NET" },
        { project: "Web Front End: Vue SPA" },
        { project: "Web Front End: ASP.NET Core MVC" },
        { project: "Cross Platform: Linux to Windows two interface" },
      ],
      progammingLanguages: [
        { language: "C" },
        { language: "C++" },
        { language: "C#" },
        { language: "COBOL" },
        { language: "SQL Server / T-SQL" },
        { language: "MySQL" },
        { language: "Oracle" },
        { language: "ASP .NET MVC" },
        { language: ".NET Framework" },
        { language: ".NET Core" },
        { language: "SPA - Vue platform" },
        { language: "CSS -  Cascading Style Sheets -> Material Design / Bootstrap" },
        { language: "javascript -> extending the Vue platform" },
        { language: "Signal R: Two-way binding and updates to browser applications without polling" },
        { language: "Web Sockets" },
        { language: "cURL & axios" },
        { language: "Operating Systems: Windows, Linux, Apple" },
        { language: "Cloud Platforms: Microsoft Azure, Amazon AWS, Linode" },
        { language: "Virtualization: Hyper-V and VMWare" },
        { language: "Containerization: Docker" },
      ],
    };
  },
};
</script>
<style scoped>
.pageLayout {
  min-height: 800px;
}
.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}
h1 {
  color: white;
}
.ListBlock {
  color: black;
}

ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}
ul {
  display: inline-block;
  text-align: left;
}

.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color: antiquewhite;
  color: black;
  opacity: 0.8;
}
.languages {
  background-color: salmon;
  color: white;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}
html,
body {
  height: 100%;
}
</style>
