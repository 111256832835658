<template>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link href="/css/styles.css" type="text/css" />
      <!--Links to Bootstrap 3,4 & 5 below.  I choose 4, others commented out. -->
        <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
        />
        <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css" rel="stylesheet"> -->
  </head>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary home">
    <!--Brand Name-->
    <a class="navbar-brand" href="#">LETC</a>

    <!--Toggler/Collapse Button-->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#collapsibleNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!--Navbar Links-->
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Home </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/industries">Industries </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/projects">Projects </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/languages">Languages </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/about">About </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/contact">Contact </router-link>
        </li>
        <li>
          <a href="https://letcconsultantwebstorage.z13.web.core.windows.net/login" class="nav-link">Consultant Portal</a>
        </li>
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      homeTitle: "Industries Served",
      industries: [
        { industry: "Healthcare" },
        { industry: "Insurance" },
        { industry: "Banking" },
        { industry: "Financial Services" },
        { industry: "High Tech" },
        { industry: "Manufacturing" },
      ],
      projectTypes: [
        {
          idx:1,
          project:
            "B2B Interface - Securely expose legacy systems to Clients via realtime API",
          description: "LETC Consultants built a custom interface function in C that adds GUID fucntionality to COBOL programs, and then created a Multi-Mastering synchronizer to keep COBOL and SQL databases in synch across transactions initaited from either side.  Code is cross platform running on both Windows and Linux, and leverages Docker containerization for rapid deployment in CI/CD."  
        },
        { project: "API's -> RESTful: xml and json w/ Swagger",
          description:"LETC Consultants architected, developed and deployed a solution for a large insurer to accept real time data feeds for Policy Applicaitons through a RESTful API.", },
        { project: "Web Applications: Three tier .NET",
          description:"", },
        { project: "Web Front End: Vue SPA",
          description:"LETC Consultants built customer facing website as Single Page Application (SPA) leveraging Vue and axios", },
        { project: "Web Front End: ASP.NET Core MVC",
          description:"", },
        { project: "Cross Platform: Linux to Windows two interface",
          description:"", },
      ],
      progammingLanguages: [
        { language: "C" },
        { language: "C++" },
        { language: "C#" },
        { language: "SQL / T-SQL" },
        { language: "COBOL" },

        { platform: "Java" },
      ],
      jobTitles: [
      { name: "Product designer", id: 1 },
      { name: "Full-stack developer", id: 2 },
      { name: "Product manager", id: 3 },
      { name: "Senior front-end developer", id: 4 }
      ],
      selectedJobTitle: null
    };
  },
};
</script>
<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script> -->

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

nav {
  padding: 30px;
  align-items: left;
  text-align: left;
  justify-content: space-between;
  color: white;
  /* background-color: #1916deae; */
  background-color: blue;
}

nav a {
  font-weight: bold;
  color: white;
}

nav a.router-link-exact-active {
  color: rgb(224, 62, 81);
}

.title_text {
  padding-top: 75px;
  font-family: "Jost", sans-serif;
  vertical-align: bottom;
}

.circle {
  background: rgba(36, 51, 213, 0.71);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  box-shadow: 0 0 0 5px #f1f1f1;
  margin: 10px;
  color: white;
}

.text_circle {
  font-size: 36px;
  margin-bottom: 50px;
  line-height: 100px;
  text-align: center;
  /* font-family: "Ariel", sans-serif; */
}

#navCollapse {
  padding: 30px;
  align-items: left;
  text-align: left;
  justify-content: space-between;
  color: white;
  /* background-color: #1916deae; */
  background-color: blue;
}
</style>
