<template>
  <div class="pageLayout">
    <div class="sky">
      <div class="industries container">
        <h1>Industries We Serve:</h1>
        <div class="container-fluid">
          <p><em>Our talented consultants have successfully developed applications for clients in these industries:</em></p>
          <div class="ListBlock">
            <ul>
              <div
                class="list"
                v-for="(item, index) in industries"
                v-bind:key="index"
              >
                <li>{{ item.industry }}</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
export default {
  name: "App",
  data() {
    return {
      homeTitle: "Industries Served",
      industries: [
        { industry: "Healthcare" },
        { industry: "Insurance" },
        { industry: "Banking" },
        { industry: "Financial Services" },
        { industry: "High Tech" },
        { industry: "Manufacturing" },
        { industry: "Government" },
        { industry: "Communications" },
      ],
      projectTypes: [
        {
          project:
            "B2B Interface - Securely expose legacy systems to Clients via realtime API",
        },
        { project: "API's -> RESTful: xml and json w/ Swagger" },
        { project: "Web Applications: Three tier .NET" },
        { project: "Web Front End: Vue SPA" },
        { project: "Web Front End: ASP.NET Core MVC" },
        { project: "Cross Platform: Linux to Windows two interface" },
      ],
    };
  },
};
</script>
<style scoped>
.pageLayout {
  min-height: 800px;
}
h1 {
  color: white;
}
.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}
.ListBlock {
  background-color: salmon;
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

/* .container {
  padding: 2em;
  background: #eee;
  text-align: left;
  background-color: grey;
  opacity: 0.5;
  color: yellow;
} */

ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}
.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color:antiquewhite;
  color: black;
  opacity: 0.8;
}

html,body {
  height:100%;
}
</style>
