<template>
  <head>
    <link href="/css/styles.css" type="text/css" />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
    />
  </head>
  <div class="pageLayout">
    <div class="sky">
      <div class="about container">
        <h1>About LETC</h1>
        <div class="container-fluid" style="text-align: left">
          <div class="row">
            <div class="mission">
              <h2>Our Mission Statement:</h2>
              <div class="col-lg-12">
                <p>
                  Our Mission is to add value for our clients by helping them to maximize the potential of their businesses 
                  through development of innovative technologies that facilitate or enhance the effectiveness and efficiency 
                  of operations.  In pursuing this mission, we honor our commitments, we keep our focus on client service and 
                  treating our customers fairly, and we always make it a priority to deliver our projects on time and 
                  on budget in such a manner that every client will want to refer us.
                </p>
              </div>
            </div>
            <br />
            <div class="team">
              <h2>Our Team:</h2>
              <div class="col-lg-12">
                <p>
                  LETC is powered by a core team of programmers who are passionate about technology.  
                  Many of us spent a significant portion of our careers at Microsoft, and we have a deep 
                  understanding of the Microsoft Stack as well as of .NET programming.  We are able to 
                  augment our Microsoft core knowledge with trusted specialists in other particular 
                  technologies as needed for given projects.  Our consultants are further 
                  grouped by function, including:
                </p>
                <ul>
                  <li>Program Managers</li>
                  <li>Developers</li>
                  <li>Testers</li>
                  <li>Deployment Engineers</li>
                </ul>
              </div>
              <div class="col-lg-12">
                <p>
                  We often partner with our client's resource teams to build an
                  integrated project team.
                </p>
                <p>
                  While our core focus is the Microsoft Stack, we also expand
                  into Linux, Vue, Java and other systems as needed by our
                  clients. We pride ourselves on our ability interface and
                  conect with a wide variety of legacy systems, including
                  Oracle, MySql, Cassandra and COBOL, among others.
                </p>
              </div>
            </div>

            <br />
            <div class="services">
              <h2>Our Services:</h2>
              <div class="col-lg-12">
                <p>
                  We pride ourselves in building innovative solutions to tough
                  business problems. Our strengths lie in process automation,
                  leveraging leading edge technologies. We collaborate with our clients
                  to achieve positive ROI projects that employ workflow automation
                  to enable a more effficient process.
                </p>
              </div>
              <div class="col-lg-12">
                <p>
                  Our main focus is the Microsoft Stack, including SQL Server,
                  .NET Core APIs, and Microsoft MVC / WinForms / and WPF Client
                  architecture.  We also provide visually engaging user interface designs 
                  using modern Material Design / Bootstrap / SPA and Vue technologies.
                  Our web applications are interactive, fast, and mobile / form factor aware, 
                  to include Desktop, laptop, tablet and mobile form factors.
                </p>
              </div>
              <div class="col-lg-12">
                <p>
                  We also provide programming services in Web Design, and we
                  often leverage the Vue stack for SPA Websites, pairing it with
                  Microsoft .NET APIs through axios.
                </p>
              </div>
              <div class="col-lg-12">
                <p>
                  Our services also include a rich low-level language capability
                  using C and C++ to bridge the gap for legacy systems B2B
                  integration. We build real time APIs for legacy systems
                  written in COBOL, including multi-mastering between COBOL and
                  SQL databases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pageLayout {
  /* max-width: 750px; */
  min-height: 800px;
}
.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}

h1 {
  color: white;
}

ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}
.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color: antiquewhite;
  color: black;
  opacity: 0.8;
}

html,
body {
  height: 100%;
}

.mission {
  background-color: aqua;
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.team {
  background-color:rgb(237, 154, 237);
  color: black;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}

.services {
  background-color: salmon;
  color: white;
  margin: 10px;
  padding: 10px;
  opacity: 0.9;
}
</style>
