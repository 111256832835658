<template>
  <body>
  <div class="pageLayout">
    <div class="sky">
      <div class="projects container">
        <h1>Project Experience</h1>
        <div class="container-fluid">
          <p>
            <em>
              Our experienced consultants have architected, designed, developed, tested, and deployed
              the following projects. These selected projects represent a subset
              of the total project list, and are a good indicator of our project
              experience.
            </em>
          </p>
          <div v-if="exp" class="container-fluid h-100">
            <div class="row">
              <div class="col-md-1">Project:</div>
              <div class="col-md-7">
                <form>
                  <select id="projectSelect" @change="getSelectedProject()">
                    <option>Agent Policy Origination eApp</option>
                    <option>Claims Customer Service App</option>
                    <option>Docker</option>
                    <option>FHLB AHP Loan System</option>
                    <option>Mortgage Origination eApp</option>
                    <option>Multi-Mastering COBOL to SQL</option>
                    <option>Web Front End</option>
                  </select>
                  <!-- Below button replaced by watch function-->
                  <!-- <input type="button" @click="getSelectedProject()" value="Select Project" /> -->
                </form>
              </div>
                <div class="col-md-3">
                    <button @click="getExperience" class="btn">
                      Show Experience
                    </button>
                </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="project">
                  <h4><em>Project:</em></h4>
                  <p>{{ projectName }}</p>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tech">
                  <h4><em>Key Technologies: </em></h4>
                  <p>{{ keyTechnology }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="desc">
                  <h4><em>Description:</em></h4>
                  <p>{{ description }}</p>
                </div>
              </div>
              <div class="col-md-6 flex-grow-1">
                <div class="benefit">
                  <h4><em>Client Benefits:</em></h4>
                  <p>{{ clientBenefit }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="arch">
                <h4><em>Architecture: </em></h4>
                <img id="ArchImage" :src="ArchImg" alt="Architecture Diagram"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
</template>

<script scoped>
import axios from "axios";
export default {
  name: "App",
  async created() {
    let config = {
      headers: {
        Accept: "application/json",
      },
    };
    this.exp = await axios
      .get(
        "https://letcapi20221017122915.azurewebsites.net/api/Web/GetExperienceList",
        config
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      projIdx: 0,
      exp: [],
      projectName: "",
      keyTechnology: "",
      clientBenefit: "",
      description: "",
      ArchImg:[],
      response: "",
    };
  },
  watch: {
    projectSelect() {
      this.getSelectedProject();
      this.getExperience();
    },
  },
  methods: {
    mounted() {
      console.log("Component is mounted");
      // alert("Mounted");
      this.getSelectedProject();
      // this.getExperience();
    
    },

    

    getSelectedProject() {
      this.projIdx = document.getElementById("projectSelect").selectedIndex;
      this.getExperience();
    },

    async getExperience() {


      this.projectName = this.exp[this.projIdx].projectName;
      this.keyTechnology = this.exp[this.projIdx].keyTechnology;
      this.clientBenefit = this.exp[this.projIdx].clientBenefit;
      this.description = this.exp[this.projIdx].description;
      
      this.ArchImg = "data:image/png;base64," + this.exp[this.projIdx].archImgBytes;

    },


  },
};
</script>
<style scoped>
.pageLayout {
  /* max-width: 750px; */
  min-height: 800px;
  
}

h1 {
  color: white;
}

.sky {
  /* background-color: #eee; */
  background-image: url("../assets/LeadingEdgeLeft.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: 750px; */
  min-height: 600px;
  min-width: 350px;
  width: auto;
  height: auto;

  padding-top: 50px;
  padding-bottom: 50px;
}

ul {
  display: inline-block;
  text-align: left;
}

.list {
  text-align: left;
}

button {
  background-color: blue;
  font-size: small;
  color: white;
  border-radius: 10px;
}
.project {
  background-color: aqua;
  color: black;
  margin: 10px;
  padding: 10px;
  min-height: 100px;
  opacity: 0.9;
}

.tech {
  background-color:rgb(237, 154, 237);
  color: black;
  margin: 10px;
  padding: 10px;
  min-height: 100px;
  opacity: 0.9;
}

.desc {
  background-color: salmon;
  color: white;
  margin: 10px;
  padding: 10px;
  min-height: 300px;
  opacity: 0.9;
}

.benefit {
  background-color: darkgreen;
  color: white;
  margin: 10px;
  padding: 10px;
  min-height: 300px;
  opacity: 0.9;
}

.container-fluid {
  text-align: left;
  padding: 20px;
  margin: 2px;
  background-color: antiquewhite;
  color: black;
  opacity: 0.8;
}

html,
body {
  height: 100%;
  min-width: 350px;
}
</style>
